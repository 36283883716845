<template>
  <div class="flex flex-col my-10 lg:flex-row relative" id="content">
    <div class="w-full xl:pr-12">
      <div class="relative" id="general">
        <div
          class="prose max-w-full dark:prose-invert"
          v-html="hotel?.content"
        ></div>
        <ClientOnly fallback-tag="div" fallback="Book Now, Rest Easy...">
          <ElementsAds />
        </ClientOnly>
      </div>
    </div>
 
  </div>
</template>
<script setup>
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
</script>
